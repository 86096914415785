var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showSearch,
              expression: "showSearch",
            },
          ],
          ref: "queryForm",
          attrs: {
            model: _vm.queryParams,
            size: "small",
            inline: true,
            "label-width": "68px",
          },
        },
        [
          this.source === "DEVICE_GROUP"
            ? _c(
                "el-form-item",
                { attrs: { label: "分组", prop: "groupId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "选择分组",
                        clearable: "",
                        id: "groupId",
                      },
                      on: { change: _vm.getList },
                      model: {
                        value: _vm.queryParams.groupId,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "groupId", $$v)
                        },
                        expression: "queryParams.groupId",
                      },
                    },
                    _vm._l(_vm.deviceGroups, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "厂商型号", prop: "qCompanyModelType" } },
            [
              _c("el-cascader", {
                staticStyle: { width: "250px" },
                attrs: {
                  options: _vm.companyModelTypeTree,
                  props: { checkStrictly: true },
                  clearable: "",
                },
                on: { change: this.handleQuery },
                model: {
                  value: _vm.qCompanyModelType,
                  callback: function ($$v) {
                    _vm.qCompanyModelType = $$v
                  },
                  expression: "qCompanyModelType",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "SN", prop: "number" } },
            [
              _c("el-input", {
                staticClass: "sf-middle",
                attrs: { placeholder: "请输入SN", clearable: "", id: "number" },
                on: { change: this.handleQuery },
                model: {
                  value: _vm.queryParams.number,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "number", $$v)
                  },
                  expression: "queryParams.number",
                },
              }),
            ],
            1
          ),
          _vm.source === "DEVICE_GROUP"
            ? _c(
                "el-form-item",
                { attrs: { label: "", prop: "noGroup" } },
                [
                  _c("el-checkbox", {
                    attrs: { label: "不在组中", border: "", size: "small" },
                    on: { change: _vm.groupStatus },
                    model: {
                      value: _vm.queryParams.noGroup,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryParams, "noGroup", $$v)
                      },
                      expression: "queryParams.noGroup",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "mini",
                  },
                  on: { click: _vm.handleQuery },
                },
                [_vm._v("搜索")]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-refresh", size: "mini" },
                  on: { click: _vm.resetQuery },
                },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "mb8", attrs: { gutter: 10 } },
        [
          false
            ? _c(
                "el-col",
                { attrs: { span: 1.5 } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["device:device:add"],
                          expression: "['device:device:add']",
                        },
                      ],
                      staticClass: "text-success",
                      attrs: {
                        type: "primary",
                        plain: "",
                        icon: "el-icon-plus",
                        size: "mini",
                      },
                      on: { click: _vm.handleAdd },
                    },
                    [_vm._v("新增")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.source === undefined
            ? _c(
                "el-col",
                { attrs: { span: 1.5 } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["device:device:remove"],
                          expression: "['device:device:remove']",
                        },
                      ],
                      staticClass: "text-danger",
                      attrs: {
                        type: "danger",
                        plain: "",
                        icon: "el-icon-delete",
                        size: "mini",
                        disabled: _vm.multiple,
                      },
                      on: { click: _vm.handleDelete },
                    },
                    [_vm._v("删除")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.source === undefined
            ? _c(
                "el-col",
                { attrs: { span: 1.5 } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["device:device:export"],
                          expression: "['device:device:export']",
                        },
                      ],
                      attrs: {
                        type: "warning",
                        plain: "",
                        icon: "el-icon-download",
                        size: "mini",
                      },
                      on: { click: _vm.handleExport },
                    },
                    [_vm._v("导出")]
                  ),
                ],
                1
              )
            : _vm._e(),
          false
            ? _c(
                "el-col",
                { attrs: { span: 1.5 } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["system:user:import"],
                          expression: "['system:user:import']",
                        },
                      ],
                      attrs: {
                        type: "info",
                        plain: "",
                        icon: "el-icon-upload2",
                        size: "mini",
                      },
                      on: { click: _vm.handleImport },
                    },
                    [_vm._v("导入")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.source === "DEVICE_GROUP"
            ? _c(
                "el-col",
                { attrs: { span: 1.5 } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["group:device:leave"],
                          expression: "['group:device:leave']",
                        },
                      ],
                      staticClass: "text-warning",
                      attrs: {
                        type: "warning",
                        plain: "",
                        icon: "el-icon-pie-chart",
                        size: "mini",
                        disabled: _vm.disableLeaveBtn,
                      },
                      on: { click: _vm.handleLeaveGroup },
                    },
                    [_vm._v("退出组")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.source === "DEVICE_GROUP"
            ? _c(
                "el-col",
                { attrs: { span: 1.5 } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["group:device:join"],
                          expression: "['group:device:join']",
                        },
                      ],
                      staticClass: "text-success",
                      attrs: {
                        type: "success",
                        plain: "",
                        icon: "el-icon-plus",
                        size: "mini",
                        disabled: _vm.multiple,
                      },
                      on: { click: _vm.handleJoinGroup },
                    },
                    [_vm._v("加入组")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("right-toolbar", {
            attrs: { showSearch: _vm.showSearch },
            on: {
              "update:showSearch": function ($event) {
                _vm.showSearch = $event
              },
              "update:show-search": function ($event) {
                _vm.showSearch = $event
              },
              queryTable: _vm.getList,
            },
          }),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.deviceList },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", width: "55", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { label: "SN", align: "center", prop: "number" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "绑定用户数",
              align: "center",
              prop: "bindUserCount",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "产品厂商", align: "center", prop: "companyName" },
          }),
          _c("el-table-column", {
            attrs: { label: "产品系列", align: "center", prop: "modelName" },
          }),
          _c("el-table-column", {
            attrs: { label: "产品型号", align: "center", prop: "typeName" },
          }),
          _vm.source === "DEVICE_GROUP"
            ? _c("el-table-column", {
                attrs: { label: "设备组", align: "center", prop: "groupName" },
              })
            : _vm._e(),
          false
            ? _c("el-table-column", {
                attrs: {
                  label: "设置页面地址",
                  align: "center",
                  prop: "setPage",
                },
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              align: "center",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    false
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["device:device:edit"],
                                expression: "['device:device:edit']",
                              },
                            ],
                            attrs: {
                              size: "mini",
                              type: "text",
                              icon: "el-icon-edit",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleUpdate(scope.row)
                              },
                            },
                          },
                          [_vm._v("修改")]
                        )
                      : _vm._e(),
                    _vm.source === undefined
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["device:device:remove"],
                                expression: "['device:device:remove']",
                              },
                            ],
                            staticClass: "text-warning",
                            attrs: {
                              size: "mini",
                              type: "text",
                              icon: "el-icon-delete",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e(),
                    _vm.source === "DEVICE_GROUP"
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["group:device:leave"],
                                expression: "['group:device:leave']",
                              },
                            ],
                            staticClass: "text-warning",
                            attrs: {
                              size: "mini",
                              type: "text",
                              icon: "el-icon-pie-chart",
                              disabled: !scope.row.groupId,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleLeaveGroup(scope.row)
                              },
                            },
                          },
                          [_vm._v("退组")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.pageNum,
          limit: _vm.queryParams.pageSize,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryParams, "pageNum", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryParams, "pageSize", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.open,
            width: "500px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.open = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "厂商型号", prop: "companyModel" } },
                [
                  _c("el-cascader", {
                    staticStyle: { width: "250px" },
                    attrs: { options: _vm.companyModelTree, clearable: "" },
                    model: {
                      value: _vm.form.companyModel,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "companyModel", $$v)
                      },
                      expression: "form.companyModel",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "SN", prop: "number" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入SN" },
                    model: {
                      value: _vm.form.number,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "number", $$v)
                      },
                      expression: "form.number",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.upload.title,
            visible: _vm.upload.open,
            width: "400px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.upload, "open", $event)
            },
          },
        },
        [
          _c(
            "el-upload",
            {
              ref: "upload",
              attrs: {
                limit: 1,
                accept: ".xlsx, .xls",
                headers: _vm.upload.headers,
                action:
                  _vm.upload.url +
                  "?updateSupport=" +
                  _vm.upload.updateSupport +
                  "&autoRegister=" +
                  _vm.upload.autoRegister,
                disabled: _vm.upload.isUploading,
                "on-progress": _vm.handleFileUploadProgress,
                "on-success": _vm.handleFileSuccess,
                "auto-upload": false,
                drag: "",
              },
            },
            [
              _c("i", { staticClass: "el-icon-upload" }),
              _c("div", { staticClass: "el-upload__text" }, [
                _vm._v("将文件拖到此处，或"),
                _c("em", [_vm._v("点击上传")]),
              ]),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip text-center",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [
                      _c("el-checkbox", {
                        attrs: {
                          label: "更新已存在的数据",
                          border: "",
                          size: "mini",
                        },
                        model: {
                          value: _vm.upload.updateSupport,
                          callback: function ($$v) {
                            _vm.$set(_vm.upload, "updateSupport", $$v)
                          },
                          expression: "upload.updateSupport",
                        },
                      }),
                      _c("el-checkbox", {
                        attrs: {
                          label: "注册不存在的型号",
                          border: "",
                          size: "mini",
                        },
                        model: {
                          value: _vm.upload.autoRegister,
                          callback: function ($$v) {
                            _vm.$set(_vm.upload, "autoRegister", $$v)
                          },
                          expression: "upload.autoRegister",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("span", [_vm._v("仅允许导入xls、xlsx格式文件。")]),
                  _c(
                    "el-link",
                    {
                      staticStyle: {
                        "margin-top": "15px",
                        "font-size": "12px",
                        "vertical-align": "baseline",
                      },
                      attrs: { type: "primary", underline: false },
                      on: { click: _vm.importTemplate },
                    },
                    [_vm._v("下载模板")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.submitFileForm },
                },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.upload.open = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加到组",
            visible: _vm.dialogJoinGroup,
            width: "360px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogJoinGroup = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "joinGroupForm",
              attrs: { model: _vm.joinGroupForm, rules: _vm.joinGroupRules },
            },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "选择分组" },
                  model: {
                    value: _vm.joinGroupForm.id,
                    callback: function ($$v) {
                      _vm.$set(_vm.joinGroupForm, "id", $$v)
                    },
                    expression: "joinGroupForm.id",
                  },
                },
                _vm._l(_vm.calDeviceGroups, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: {
                      label: item.name,
                      value: item.id,
                      disabled: item.disabled,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogJoinGroup = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.submitJoinGroupForm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }