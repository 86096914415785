import request from '@/utils/request'

// 查询厂商列表
export function listCompany(query) {
  return request({
    url: '/company/list',
    method: 'get',
    params: query
  })
}

// 查询厂商详细
export function getCompany(id) {
  return request({
    url: '/company/' + id,
    method: 'get'
  })
}

// 新增厂商
export function addCompany(data) {
  return request({
    url: '/company',
    method: 'post',
    data: data
  })
}

// 修改厂商
export function updateCompany(data) {
  return request({
    url: '/company',
    method: 'put',
    data: data
  })
}

// 删除厂商
export function delCompany(id) {
  return request({
    url: '/company/' + id,
    method: 'delete'
  })
}
//
export function recoverCompany(id) {
  return request({
    url: '/company',
    method: 'put',
    data: { id: id, deleted: false }
  })
}
// 获取字典选择框列表
export function optionSelect() {
  return request({
    url: '/company/optionSelect',
    method: 'get'
  })
}

// 获取厂商型号树
export function camTreeSelect() {
  return request({
    url: '/company/camTreeSelect',
    method: 'get'
  })
}

