<template>
  <div class="dashboard-editor-container">

    <panel-group
        :total-user="this.totalUser"
        :total-device="this.totalDevice"
        :total-report="this.totalReport"
        :total-article="this.totalArticle"
        @handleSetLineChartData="handleSetLineChartData"
    />

    <el-row style="background:#fff;padding:16px 16px 0;margin-bottom:32px;">
      <line-chart :chart-data="lineChartData" />
    </el-row>


  </div>
</template>

<script>
import PanelGroup from './dashboard/PanelGroup'
import LineChart from './dashboard/LineChart'
import { getStatisticsData } from "@/api/common";

let lineChartDataList = {
  user: {
    countTitle: [],
    horizontalAxis: [],
    countData: [[]],
  }
}

export default {
  name: 'Index',
  components: {
    PanelGroup,
    LineChart,
  },
  data() {
    return {
      totalUser: [],
      totalDevice: [],
      totalReport: [],
      totalArticle: [],
      lineChartData: lineChartDataList.user,
    }
  },
  created() {
    // this.handleSetLineChartData();
    this.getRemoteData();
  },
  methods: {
    handleSetLineChartData(type) {
      this.lineChartData = lineChartDataList[type]
    },
    getRemoteData() {
      getStatisticsData().then(response => {
        const allCharData = response.data;
        // this.lineChartData = allCharData.user;
        this.totalUser = allCharData.user.totalCount
        this.totalDevice = allCharData.device.totalCount
        this.totalReport = allCharData.report.totalCount
        this.totalArticle = allCharData.article.totalCount
        lineChartDataList = allCharData
      });
    }
  },
}
</script>

<style lang="scss" scoped>
.dashboard-editor-container {
  padding: 32px;
  background-color: rgb(240, 242, 245);
  position: relative;

  .chart-wrapper {
    background: #fff;
    padding: 16px 16px 0;
    margin-bottom: 32px;
  }
}

@media (max-width:1024px) {
  .chart-wrapper {
    padding: 8px;
  }
}
</style>
