import request from '@/utils/request'

// 查询报告列表列表
export function listReport(query) {
  return request({
    url: '/report/list',
    method: 'get',
    params: query
  })
}

// 查询报告列表详细
export function getReport(id) {
  return request({
    url: '/report/' + id,
    method: 'get'
  })
}

export function getReports(ids) {
  return request({
    url: '/report/' + ids,
    method: 'get'
  })
}

// 新增报告列表
export function addReport(data) {
  return request({
    url: '/report',
    method: 'post',
    data: data
  })
}

// 修改报告列表
export function updateReport(data) {
  return request({
    url: '/report',
    method: 'put',
    data: data
  })
}

// 删除报告列表
export function delReport(id) {
  return request({
    url: '/report/' + id,
    method: 'delete'
  })
}
export function reportExport(query) {
  return request({
    url: '/report/export',
    method: 'post',
    data: query
  })
}

export function checkNew() {
  return request({
    url: '/report/checkNew',
    method: 'get'
  })
}
